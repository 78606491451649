import React, { lazy, Suspense, Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH, ADMIN_API_BASE_ENDPOINT } from 'configs/AppConfig'
import history from '../../history'
import service from 'auth/FetchInterceptor';
import {
    getAdminUserData
} from 'redux/actions/Auth';


class AppViews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: props.token ? props.token : null,
            userData: props.userData ? props.userData : [],
            userAccessData: []
        }
    }

    componentDidMount() {
        if (!this.state.token) {
            this.isAuthenticated()
        } else {
            //this.fetchAdmin();
        }
    }

    isAuthenticated = () => {
        history.push('/auth/login')
        window.location.reload();
    }


    render() {



        const is_valid = this.state.userData.is_valid !== undefined ? this.state.userData.is_valid : true;
        const userAccessData = this.state.userData.role_id !== undefined && this.state.userData.role_id.length > 0 ? this.state.userData.role_id[0].role_access : [];
        var role_type = this.state.userData.role_id !== undefined && this.state.userData.role_id.length > 0 ?  this.state.userData.role_id[0].role_type : "";

        if (!this.state.token || !userAccessData) {
            this.isAuthenticated()
        }

        const homeRoutes = [
            <Route path='/' component={lazy(() => import(`./splash`))} />,
            <Route path='/app' component={lazy(() => import(`./splash`))} />,
        ]

        const companyRoutes = [
            userAccessData.includes('company_management') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/company/lists`} component={lazy(() => import(`./company/list-company`))} /> : null,
            userAccessData.includes('company_management') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/company/add`} component={lazy(() => import(`./company/add-company`))} /> : null,
            userAccessData.includes('company_management') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/company/edit`} component={lazy(() => import(`./company/add-company`))} /> : null
        ]

        const TicketListSale = React.lazy(() => import('./ticket/list-ticket'));
        const TicketListMaintenance = React.lazy(() => import('./ticket/list-ticket'));
        const TicketListCoaching = React.lazy(() => import('./ticket/list-ticket'));
        const ticketRoutes = [
            userAccessData.includes('ticket_management') || role_type === 'default' ?
                <TicketListSale path={`${APP_PREFIX_PATH}/ticket/sale`}  category="Sales" key={ Date.now() }/>
            : null,

            userAccessData.includes('ticket_management') || role_type === 'default' ?
                <TicketListMaintenance path={`${APP_PREFIX_PATH}/ticket/maintenance`}  category="Maintenance" key={ Date.now() }/>
            : null,

            userAccessData.includes('ticket_management') || role_type === 'default' ?
                <TicketListCoaching path={`${APP_PREFIX_PATH}/ticket/coaching`}  category="Coaching" key={ Date.now() }/>
            : null,

            userAccessData.includes('ticket_management') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/ticket/edit`} component={lazy(() => import(`./ticket/add-ticket`))} /> : null,

        ]

        const deviceRoutes = [
            userAccessData.includes('device_management') || userAccessData.includes('add_device') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/device/lists`} component={lazy(() => import(`./device/list-device`))} /> : null,

            userAccessData.includes('device_management') || userAccessData.includes('add_device') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/device/add`} component={lazy(() => import(`./device/add-device`))} /> : null,

            userAccessData.includes('device_management') || userAccessData.includes('add_device') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/device/edit`} component={lazy(() => import(`./device/add-device`))} /> : null,
        ]

        const leadRoutes = [
           userAccessData.includes('lead_management') || userAccessData.includes('lead_list') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/lead/lists`} component={lazy(() => import(`./lead/list-lead`))} /> : null,
           userAccessData.includes('lead_management') || userAccessData.includes('lead_purchased_report') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/lead/purchased_report`} component={lazy(() => import(`./lead/lead-report`))} /> : null,
           userAccessData.includes('lead_management') || userAccessData.includes('lead_user_report') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/lead/user_report`} component={lazy(() => import(`./lead/lead-report-user-wise`))} /> : null,
        ];

        const allReminderList = [
            userAccessData.includes('all_reminders') || userAccessData.includes('staff_reminders') || role_type === 'default' ?
                <Route path={`${APP_PREFIX_PATH}/reminders/sim`} component={lazy(() => import(`./reminders/sim-reminders`))} /> : null,

            userAccessData.includes('all_reminders') || userAccessData.includes('staff_reminders') || role_type === 'default' ?
                <Route path={`${APP_PREFIX_PATH}/reminders/staff`} component={lazy(() => import(`./reminders/staff-reminders`))} /> : null,
        ];

        const orderManagementRoutes = [

            userAccessData.includes('order_management') || userAccessData.includes('company_orders') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/order/company`} component={lazy(() => import(`./orders/list-orders`))} /> : null ,

            userAccessData.includes('order_management') || userAccessData.includes('device_orders') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/order/device`} component={lazy(() => import(`./orders/list-device`))} /> : null ,
        ]

        const adminUserRoutes = [
            userAccessData.includes('users-admins') || userAccessData.includes('users') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/users/admins/add`} component={lazy(() => import(`./users/admins/AdminAdd`))} /> : null,
            userAccessData.includes('users-admins') || userAccessData.includes('users') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/users/admins/update`} component={lazy(() => import(`./users/admins/AdminAdd`))} /> : null ,
            userAccessData.includes('users-admins') || userAccessData.includes('users') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/users/admins/access`} component={lazy(() => import(`./users/admins/AdminAccess`))} /> : null ,
            userAccessData.includes('users-admins') || userAccessData.includes('users') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/users/admins/list`} component={lazy(() => import(`./users/admins/AdminList`))} /> : null,
        ]

        const adminUserRoleRoutes = [
            userAccessData.includes('users-role') || userAccessData.includes('users') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/users/role/list`} component={lazy(() => import(`./users/roles/list-role`))} /> : null ,
            userAccessData.includes('users-role') || userAccessData.includes('users') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/users/role/add`} component={lazy(() => import(`./users/roles/add-role`))} /> : null,
            userAccessData.includes('users-role') || userAccessData.includes('users') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/users/role/edit`} component={lazy(() => import(`./users/roles/add-role`))} /> : null,
        ]

        const expensesRoutes = [
            userAccessData.includes('manage_expenses') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/expenses/list`} component={lazy(() => import(`./expenses/list-expenses`))} /> : null,

            userAccessData.includes('manage_expenses') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/expenses/add`} component={lazy(() => import(`./expenses/add-expenses`))} /> : null,

            userAccessData.includes('manage_expenses') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/expenses/edit`} component={lazy(() => import(`./expenses/add-expenses`))} />: null
        ]

        const staffList = [
            <Route path={`${APP_PREFIX_PATH}/staff/lists`} component={lazy(() => import(`./staff/list-staff`))} />,
            <Route path={`${APP_PREFIX_PATH}/staff/add`} component={lazy(() => import(`./staff/add-staff`))} />,
            <Route path={`${APP_PREFIX_PATH}/staff/edit`} component={lazy(() => import(`./staff/add-staff`))} />,
        ]
        const staffInvoice = [
            <Route path={`${APP_PREFIX_PATH}/staff/invoice`} component={lazy(() => import(`./staff/staff-invoice`))} />,
        ]

        const staffRoutes = [
            userAccessData.includes('manage_staff') || userAccessData.includes('staff_lists') || role_type === 'default' ? staffList : null,
            userAccessData.includes('manage_staff') || userAccessData.includes('staff_invoice') || role_type === 'default' ? staffInvoice : null,
        ];

        const packagePriceRoutes = [
            userAccessData.includes('package_management') || userAccessData.includes('package_add') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/price_package`} component={lazy(() => import(`./package/add-package`))} /> : null,
        ]

        const custodyRoutes = [
            userAccessData.includes('manage_custody') || userAccessData.includes('manage_staff') ||role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/custody/list`} component={lazy(() => import(`./custody/list-custody`))} /> : null,

            userAccessData.includes('manage_custody') ||  userAccessData.includes('manage_staff') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/custody/add`} component={lazy(() => import(`./custody/add-custody`))} /> : null,

            userAccessData.includes('manage_custody') ||  userAccessData.includes('manage_staff') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/custody/edit`} component={lazy(() => import(`./custody/add-custody`))} />: null
        ]

        const approveRoutes = [
            userAccessData.includes('approve_management') || userAccessData.includes('approve_list') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/approve/lists`} component={lazy(() => import(`./approve`))} /> : null,
            userAccessData.includes('approve_management') || userAccessData.includes('approve_mgmt') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/approve/management`} component={lazy(() => import(`./approve/management`))} /> : null,
        ]

        const supplierRoutes = [
            userAccessData.includes('manage_supplier') || userAccessData.includes('manage_expenses') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/supplier/list`} component={lazy(() => import(`./purchasing_suppliers/list-supplier`))} /> : null,

            userAccessData.includes('manage_supplier') || userAccessData.includes('manage_expenses') ||  role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/supplier/add`} component={lazy(() => import(`./purchasing_suppliers/add-supplier`))} /> : null,

            userAccessData.includes('manage_supplier') || userAccessData.includes('manage_expenses') || role_type === 'default' ?
            <Route path={`${APP_PREFIX_PATH}/supplier/edit`} component={lazy(() => import(`./purchasing_suppliers/add-supplier`))} />: null
        ]


        return (
            <Suspense fallback={<Loading cover="content" />}>


                <Switch>
                    {
                        adminUserRoutes
                    }
                    {
                        adminUserRoleRoutes
                    }
                    {
                        companyRoutes
                    }
                    {
                        deviceRoutes
                    }
                    {
                        ticketRoutes
                    }
                    {
                        allReminderList
                    }
                    {
                        orderManagementRoutes
                    }
                    {
                        expensesRoutes
                    }
                    {
                        staffRoutes
                    }
                    {
                        leadRoutes
                    }
                    {
                        packagePriceRoutes
                    }
                    {
                        custodyRoutes
                    }
                    {
                       supplierRoutes
                    }
                    {
                        userAccessData.includes('approve_list') || userAccessData.length == 0 || role_type === 'default' ? approveRoutes : null
                    }
                    {
                        // DASHBOARD PATH
                        userAccessData.includes('home') || userAccessData.length == 0 || role_type === 'default' ? homeRoutes :
                        <Route path={`${APP_PREFIX_PATH}`} component={lazy(() => import(`./splash/default`))} />
                    }

                    <Route path={`${APP_PREFIX_PATH}/error`} component={lazy(() => import(`./errors/error-page-1`))} />

                    <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/error/`} />

                </Switch>
            </Suspense>
        )
    }
}

const mapStateToProps = ({ theme, auth }) => {
    const { locale, direction } = theme;
    const { token, userData } = auth;
    return { locale, token, userData, direction }
};

const mapDispatchToProps = {
    getAdminUserData
}


export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AppViews));
